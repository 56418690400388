import React from 'react';
import { FeatureContainer, FeatureWrap, FeatureItems, FeatureH1, FeatureButtonLink } from './FeatureElements';
import { useInView } from 'react-intersection-observer';
import Slider from '../Slider';
import Allergy from '../Allergy';
import { Fragment } from 'react/cjs/react.production.min';

function Feature() {
  const { ref: featureRef, inView: featureInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <Fragment>
      <FeatureContainer
        ref={featureRef}
        inView={featureInView}
        image={require('../../images/SG16.AVIF')} // Dynamically load the image
      >
        <FeatureWrap>
          <FeatureItems>
            <FeatureH1>Dive Into Our Menu</FeatureH1>
            <FeatureButtonLink to="/coming-soon" aria-label="Redirect to Coming Soon">
              Order Now
            </FeatureButtonLink>
          </FeatureItems>
        </FeatureWrap>
      </FeatureContainer>
      <Slider />
      <Allergy />
    </Fragment>
  );
}

export default React.memo(Feature);
