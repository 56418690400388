import styled from 'styled-components';
import Pic1 from '../../images/SG1.AVIF';
import Pic2 from '../../images/SG2.AVIF';
import Pic3 from '../../images/SG3.AVIF';
import Pic4 from '../../images/SG4.AVIF';
import Pic5 from '../../images/SG5.AVIF';
import Pic6 from '../../images/SG6.AVIF';
import Pic7 from '../../images/SG7.AVIF';
import Pic8 from '../../images/SG8.AVIF';
import Pic9 from '../../images/SG9.AVIF';
import Pic10 from '../../images/SG10.AVIF';
import Pic11 from '../../images/SG11.AVIF';
import Pic12 from '../../images/SG12.AVIF';
import Pic13 from '../../images/SG13.AVIF';
import Pic14 from '../../images/SG14.AVIF';
import Pic15 from '../../images/SG15.AVIF';
import Pic16 from '../../images/SG16.AVIF';
import Pic17 from '../../images/SG17.AVIF';
import Pic18 from '../../images/SG18.AVIF';
import Pic19 from '../../images/SG19.AVIF';
import Pic20 from '../../images/SG20.AVIF';
import Pic21 from '../../images/SG21.AVIF';
import Pic22 from '../../images/SG22.AVIF';
import Pic23 from '../../images/SG23.AVIF';
import Pic24 from '../../images/SG24.AVIF';
import Pic25 from '../../images/SG25.AVIF';

export const Photos = [
  { className: 'img', alt: 'pic-1', image: Pic1 },
  { className: 'img', alt: 'pic-2', image: Pic2 },
  { className: 'img', alt: 'pic-3', image: Pic3 },
  { className: 'img', alt: 'pic-4', image: Pic4 },
  { className: 'img', alt: 'pic-5', image: Pic5 },
  { className: 'img', alt: 'pic-6', image: Pic6 },
  { className: 'img', alt: 'pic-7', image: Pic7 },
  { className: 'img', alt: 'pic-8', image: Pic8 },
  { className: 'img', alt: 'pic-9', image: Pic9 },
  { className: 'img', alt: 'pic-10', image: Pic10 },
  { className: 'img', alt: 'pic-11', image: Pic11 },
  { className: 'img', alt: 'pic-12', image: Pic12 },
  { className: 'img', alt: 'pic-13', image: Pic13 },
  { className: 'img', alt: 'pic-14', image: Pic14 },
  { className: 'img', alt: 'pic-15', image: Pic15 },
  { className: 'img', alt: 'pic-16', image: Pic16 },
  { className: 'img', alt: 'pic-17', image: Pic17 },
  { className: 'img', alt: 'pic-18', image: Pic18 },
  { className: 'img', alt: 'pic-19', image: Pic19 },
  { className: 'img', alt: 'pic-20', image: Pic20 },
  { className: 'img', alt: 'pic-21', image: Pic21 },
  { className: 'img', alt: 'pic-22', image: Pic22 },
  { className: 'img', alt: 'pic-23', image: Pic23 },
  { className: 'img', alt: 'pic-24', image: Pic24 },
  { className: 'img', alt: 'pic-25', image: Pic25 },
];

export const SlideContainer = styled.div`
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: #000;

  .slide {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .slide.active {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }

  .image {
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
  }

  .image:hover {
    transform: scale(1.05);
  }

  .arrow {
    position: absolute;
    top: 50%;
    font-size: 2rem;
    color: white;
    z-index: 10;
    cursor: pointer;
    user-select: none;
    transform: translateY(-50%);
  }

  .arrow.left {
    left: 10px;
  }

  .arrow.right {
    right: 10px;
  }

  @media screen and (max-width: 280px) {
    height: 60vh;
  }
  @media screen and (max-width: 320px) {
    height: 80vh;
  }
  @media screen and (min-width: 1024px) {
    height: 65vh;
  }
`;
