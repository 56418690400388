import styled from 'styled-components';
import FeaturePic from '../../images/SG26.AVIF';


export const InformationContainer = styled.div`
    height: 100%;

`;
export const InformationWrap = styled.div`
    height: 65vh;
    border-bottom: 1px solid white;

    background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1)),
    url(${FeaturePic});
    // background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; 

    @media screen and (max-width: 280px){
        background-size: 140% 100%;
        height: 50vh;
    }   

    @media screen and (min-width: 320px){
        height: 50vh;
        background-size: 130% 100%; 
    }

    @media screen and (min-width: 375px){
        height: 50vh;
        background-size: 130% 100%; 
    }

    @media screen and (min-width: 411px){
        height: 50vh;
        background-size: 130% 100%; 
    }

    @media screen and (min-width: 540px){
        background-size: 100% 100%;
        height: 50vh;
    }

    @media screen and (min-width: 768px) {
        background-size: 100% 100%;
        height: 50vh;
    }
    @media screen and (min-width: 1024px) {
        background-size: 100% 100%;
        height: 50vh;
    }

    @media only screen and (min-width: 568px) and (orientation: landscape) {
        height: 125vh;
    }

    @media only screen and (min-width: 640px) and (orientation: landscape) {
        height: 100vh;
    }

    @media only screen and (min-width: 653px) and (orientation: landscape) {
        height: 140vh;
    }

    @media only screen and (min-width: 717px) and (orientation: landscape){
        height: 100vh;
        background-size: 100% 100%;
    }

    @media only screen and (min-width: 720px) and (orientation: landscape) {
        height: 100vh;
    }

    @media only screen and (min-width: 812px) and (orientation: landscape) {
        height: 125vh;
    }

    @media only screen and (min-width: 1024px) and (orientation: landscape) {
        height: 100vh;
    }

    @media only screen and (min-width: 1366px) and (orientation: landscape) {
        height: 100vh;
    }

`; 
export const InformationItems = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 1.5rem;

    color: white;
`;
export const InformationH1 = styled.h1`
    display: flex;
    flex-direction: center;
    justify-content: center;
    text-align: center;
    letter-spacing: 1px;
    font-size: clamp(2rem, 4vw, 3rem);
    margin-bottom: 1rem;
    text-transform: uppercase;
`; 
export const InformationP1 = styled.p`
    display: flex;
    flex-direction: center;
    justify-content: center;
    text-align: center;
    font-family: 'Poppins',sans-serif;
    font-size: clamp(1.4rem, 4vw, 1.75rem);
`; 
export const InformationH2 = styled.h1`
    display: flex;
    flex-direction: center;
    justify-content: center;
    text-align: center;
    letter-spacing: 1px;
    font-size: clamp(2rem, 4vw, 3rem);
    margin-bottom: 1rem;
    text-transform: uppercase;
`; 
export const InformationP2 = styled.p`
    display: flex;
    flex-direction: center;
    justify-content: center;
    text-align: center;
    font-family: 'Poppins',sans-serif;
    font-size: clamp(1.4rem, 4vw, 1.75rem);
`; 
export const SocialMediaWrap = styled.div`
    height: 35vh;
    background: #e31837;
    color: white;
    border-bottom: 1px solid white;

    
    @media only screen and (min-width: 320px) and (max-width: 820px) {
        height: 40vh;
    }
    
    @media only screen and (min-width: 568px) and (orientation: landscape) {
        height: 75vh;
    }

    @media only screen and (min-width: 640px) and (orientation: landscape) {
        height: 75vh;
    }

    @media only screen and (min-width: 1366px) and (orientation: landscape) {
        height: 50vh;
    }

`; 
export const SocialItems = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 1.5rem;

`; 
export const SocialH1 = styled.h1`
    display: flex;
    flex-direction: center;
    justify-content: center;
    text-align: center;
    letter-spacing: 1px;
    font-size: clamp(1.5rem, 4vw, 2.5rem);
    margin-bottom: 2rem;
    text-transform: uppercase;
`; 
export const SocialIcons = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 240px;

`; 
export const SocialIconLink = styled.a`
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    cursor: pointer;

    &:hover {
        transition: 0.2s ease-out;
        cursor: pointer;
        color: black;

`; 