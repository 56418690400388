import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Main Container for the Hero Section
export const MainContainer = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

// Video Container for the background video
export const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: none;
  }
`;

// Mute Button Styling
export const MuteButton = styled.button`
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: ${({ isMuted }) => (isMuted ? '#e31837' : 'black')};
  color: white;
  font-size: 16px;
  cursor: pointer;
  z-index: 3;

  &:hover {
    background-color: ${({ isMuted }) => (isMuted ? '#b8162b' : '#333')};
  }
`;

// Main Content Wrapper
export const MainContent = styled.div`
  position: relative;
  z-index: 2; /* Ensure content is above the video */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  padding: 0 2rem;

  @media screen and (max-width: 768px) {
    align-items: center;
    text-align: center;
    padding: 0 1rem;
  }
`;

// Text and Button Wrapper
export const MainItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 650px;
  color: #fff;
  text-transform: uppercase;
  line-height: 1.2;
  font-weight: bold;

  @media screen and (max-width: 768px) {
    align-items: center;
  }
`;

// Main Heading
export const MainH1 = styled.h1`
  font-size: clamp(2rem, 8vw, 5rem);
  margin-bottom: 1rem;
  box-shadow: 3px 5px #e31837;
  letter-spacing: 3px;
`;

// Subheading/Paragraph
export const MainP = styled.p`
  font-size: clamp(1.5rem, 2.5vw, 3rem);
  margin-bottom: 2rem;
`;

// Call-to-Action Button for Internal Links
export const MainBtnLink = styled(Link)`
  font-size: clamp(1.2rem, 4vw, 1.75rem);
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 5px;
  background: #e31837;
  color: #fff;
  transition: 0.2s ease-out;
  text-transform: none;
  text-decoration: none;

  &:hover {
    background: black;
    transition: 0.2s ease-out;
    cursor: pointer;
    color: white;
  }
`;
