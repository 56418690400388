import React, { useEffect, useRef, useState, Suspense } from 'react';
import {
  MainContainer,
  VideoContainer,
  MainContent,
  MainItems,
  MainH1,
  MainP,
  MainBtnLink,
  MuteButton,
} from './MainElements';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import { Photos } from '../Collage/Photos';
import {
  SocialMediaWrap,
  SocialItems,
  SocialH1,
  SocialIcons,
  SocialIconLink,
} from '../Information/InformationElement';

// Lazy load components for performance optimization
const About = React.lazy(() => import('../About'));
const Collage = React.lazy(() => import('../Collage'));
const Allergy = React.lazy(() => import('../Allergy'));

const Main = () => {
  const videoRef = useRef(null); // Reference for the video element
  const [isMuted, setIsMuted] = useState(true); // State to track mute/unmute

  useEffect(() => {
    const playVideo = () => {
      if (videoRef.current) {
        videoRef.current
          .play()
          .catch((error) => console.error('Error playing video:', error));
      }
    };

    playVideo(); // Try to play the video on mount
  }, []);

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted; // Toggle mute state
      setIsMuted(!isMuted); // Update state
    }
  };

  return (
    <>
      {/* Hero Section */}
      <MainContainer>
        <VideoContainer>
          <video
            ref={videoRef}
            src="/SpartansGrill.webm"
            autoPlay
            loop
            playsInline
            preload="auto"
            muted={isMuted}
          ></video>
          <MuteButton isMuted={isMuted} onClick={toggleMute}>
            {isMuted ? '🔇 Unmute' : '🔊 Mute'}
          </MuteButton>
        </VideoContainer>

        <MainContent>
          <MainItems>
            <MainH1>Spartan's Grill</MainH1>
            <MainP>Modern and Traditional Greek Fast Food Restaurant</MainP>
            <MainBtnLink to="/coming-soon" aria-label="Redirect to Coming Soon">
              Order Now
            </MainBtnLink>
          </MainItems>
        </MainContent>
      </MainContainer>

      {/* Additional Sections */}
      <Suspense fallback={<div>Loading About...</div>}>
        <About />
      </Suspense>

      <SocialMediaWrap>
        <SocialItems>
          <SocialH1>Follow Us On</SocialH1>
          <SocialIcons>
            <SocialIconLink
              href="https://www.facebook.com/SpartansGrill123/"
              target="_blank"
              aria-label="Redirect to Facebook"
              rel="noopener noreferrer"
            >
              <FaFacebook />
            </SocialIconLink>
            <SocialIconLink
              href="https://www.instagram.com/spartansgrill/"
              target="_blank"
              aria-label="Redirect to Instagram"
              rel="noopener noreferrer"
            >
              <FaInstagram />
            </SocialIconLink>
          </SocialIcons>
        </SocialItems>
      </SocialMediaWrap>

      <Suspense fallback={<div>Loading Collage...</div>}>
        <Collage photos={Photos} />
      </Suspense>

      <Suspense fallback={<div>Loading Allergy Information...</div>}>
        <Allergy />
      </Suspense>
    </>
  );
};

export default Main;
